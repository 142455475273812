body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#pops div {
  padding: 5px;
  width: 350px;
  height: 25px;
  bottom: 0px;
  margin: 5px;
  box-shadow: 0px 1px 7px 4px grey;
  border-radius: 10px;
  border: 1px white solid;
  position: fixed;
  left: 0;
  animation: animateDiv 5s 2;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  z-index: 10000;

}

@keyframes animateDiv {
  0% {
    bottom: 0px;
    top: 50px;
  }

  100% {
    bottom: 50px;
    top: 0px;
  }
}