a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
.logo{
  max-width: 50px;
  padding: 5px;
  box-shadow: 1px 1px 5px 2px #00a0971f;
    
    border-radius: 5px;
}
.nav-item{
  margin: 5px;
  cursor: pointer;
  box-shadow: 1px 1px 5px 2px #00a0971f;
  border-radius: 5px;
}
.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  
}
