.LoginView{
    margin-top: 20px;
    background-color: #f1f1f1;
    padding:10px;
  }
  .message{
    color: #910000;
    background-color: #8a8a8a;
  }
  .message p{
    padding: 10px;;
  }
  input{
    line-height: 25px;
    border: none;
    width: 100%;
    border-radius: 5px;
    
  }
  .Login label{
    text-align:start;
  }
  .Login{    
    background-color: #7CA7AD;
    padding:10px;
    padding-top: 0px;
  }
  @media only screen and (min-width: 400px) {
    .LoginView{
        width: 300px;
        margin: auto;
      } 
}